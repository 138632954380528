import {
  TextField,
  Button,
  Container,
  Box,
  Toolbar,
  Typography,
  Card,
} from "@mui/material";
import { TextFieldStyle, CardStyle, ButtonStyle } from "./Style";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const ForgotPassword = () => {
  const { handleSubmit, control } = useForm();
  const [submitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);

  const onSubmit = (data) => {
    if (!submitting) {
      setIsSubmitting(true);
      setIsError(false);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/forgot_password`, data, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((result) => {
          if (result.status === 200) {
            setSuccess(true);
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response?.data?.message);
            setIsError(true);
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        });
    }
  };

  const handleToastClose = () => {
    setToastErr(false);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Toolbar />
      <Container>
        {!isLoading && !user && (
          <Box sx={{ textAlign: "center" }}>
            {success ? (
              <Typography>
                Please check your email, a link has been sent to reset your
                password. <br /> Please check your spam/junk folder.
              </Typography>
            ) : (
              <Card sx={CardStyle}>
                <Box p={2}>
                  <Typography variant="h5" mt={3} mb={!isError ? 3 : 2}>
                    Forgot Password
                  </Typography>
                  {isError && (
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 400, color: "#d32f2f" }}
                      mb={1}
                    >
                      {message}
                    </Typography>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          sx={TextFieldStyle}
                          label="Email"
                          onChange={onChange}
                          required
                          variant="outlined"
                          type="email"
                          value={value}
                          error={error ? true : false}
                          fullWidth
                          size="small"
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Email required" }}
                    />
                    <br />{" "}
                    <Box mb={3}>
                      <Button
                        sx={ButtonStyle}
                        variant="outlined"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                      >
                        Reset Password
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Card>
            )}
          </Box>
        )}
        {toastErr && (
          <Toast
            open={toastErr}
            handleClose={handleToastClose}
            message="Oops, something went wrong!"
            severity="error"
          />
        )}
      </Container>
    </>
  );
};

export default ForgotPassword;
