import { useState, useEffect } from "react";
import axios from "axios";

const useLoggedIn = () => {
  const [user, setUser] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    async function findUser() {
      axios.defaults.withCredentials = true;
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/checkAuth`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((res) => {
          setUser(res.data.autherized);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    findUser();
  }, []);
  return {
    user,
    setUser,
    isLoading,
  };
};

export default useLoggedIn;
