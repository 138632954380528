import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";

import { Image, CardStyle, CardTitle } from "./Style";

const SinglePost = (props) => {
  const post = props.post;

  return (
    <Card sx={CardStyle} key={post.id} variant="outlined">
      <CardMedia
        component="img"
        sx={Image}
        image={`${process.env.REACT_APP_BASE_URL_IMAGE}/` + post.images}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h6" sx={CardTitle}>
            {post.title}
          </Typography>
          <Typography
            mt={{ sm: 1 }}
            sx={{
              typography: {
                xs: "subtitle2",
                sm: "subtitle1",
                md: "subtitle1",
              },
            }}
            color="text.secondary"
          >
            Category: {post.category}
          </Typography>
          <Typography
            mr={2}
            sx={{
              typography: {
                xs: "subtitle2",
                sm: "subtitle1",
                md: "subtitle1",
              },
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            color="text.secondary"
          >
            Age: {post.age + " " + post.period}
          </Typography>
          <Typography
            mr={2}
            sx={{
              typography: {
                xs: "subtitle2",
                sm: "subtitle1",
                md: "subtitle1",
              },
            }}
            color="text.secondary"
          >
            Gender: {post.gender}
          </Typography>
          <Typography
            mr={2}
            sx={{
              typography: {
                xs: "subtitle2",
                sm: "subtitle1",
                md: "subtitle1",
              },
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
            color="text.secondary"
          >
            Vaccinated: {post.vaccinated}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
          >
            {post.city + ", " + post.state}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default SinglePost;
