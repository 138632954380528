export const Image = {
    width: { xs: 108, sm: 220, md: 250 },
    mr: { xs: 1, sm: 4 },
      // objectFit: "contain",
  };
  
  export const CardStyle = {
    display: "flex",
    height: { xs: 150, sm: 220, md: 250 },
    mt: { xs: 1 },
    p: 0.3,
    boxShadow: "none",
  };
  
  export const CardTitle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    lineHeight: 1.2,
  };
  