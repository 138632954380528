import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";

const Navbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, isLoading } = useContext(UserContext);

  const drawerWidth = 240;
  const loginOrLogout = user ? "Logout" : "Login";
  const contactOrMyposts = user ? "My Posts" : "Contact";
  const navItems = ["Upload", contactOrMyposts , loginOrLogout];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const getItem = (item) =>{
    if(item === 'Upload') return "post";
    else if(item === 'My Posts') return "myposts";
    else return item.toLowerCase();
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Button
        component={Link}
        sx={{
          flexGrow: 1,
        }}
        to="/"
        key="/"
      >
        <Typography
          variant="h6"
          sx={{ my: 2, color: "rgb(0 149 246)", fontWeight: 600 }}
        >
          ADOPTYPET
        </Typography>
      </Button>
      <Divider />
      <List>
        {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                component={Link}
                to={getItem(item)}
                sx={{ textAlign: "center" }}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" elevation={1} sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Button
            component={Link}
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              color: "#fff",
            }}
            to="/"
            key="/"
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: 600,
                color: "rgb(0 149 246)",
              }}
            >
              ADOPTYPET
            </Typography>
          </Button>
          {!isLoading && (
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button
                  component={Link}
                  to={getItem(item)}
                  key={item}
                  sx={{ color: "black", fontWeight: 450 }}
                >
                  {item}
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "black",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default Navbar;
