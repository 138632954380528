import { Snackbar, Alert } from "@mui/material";
const Toast = (props) => {
  const open = props.open;
  const close = props.handleClose;
  const message = props.message;
  const severity = props.severity;
  const handleClose = () => {
    close();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
