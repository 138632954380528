import axios from "axios";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function logout() {
      axios.defaults.withCredentials = true;
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((res) => {
          setUser(false);
          navigate("/login");
        })
        .catch((err) => {});
    }
    if (user) logout();
    else navigate("/login");
    // eslint-disable-next-line
  }, [user]);

  return;
};

export default Logout;
