import {
  TextField,
  Button,
  Container,
  Box,
  Toolbar,
  Typography,
  Card,
  MenuItem,
  Autocomplete,
  ImageList,
  ImageListItem,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import {
  TextFieldStyle,
  CardStyle,
  ButtonStyle,
  TextFieldStyleAge,
  TextFieldStyleContact,
  TextFieldStyleCode,
  TextFieldStyleState,
  Images,
} from "./Style";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import {
  states,
  vaccinated,
  genders,
  catergories,
  ageType,
  cities,
} from "./constants";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import { Link } from "react-router-dom";
import Toast from "../shared/Snackbar";
import imageCompression from "browser-image-compression";

const Post = () => {
  const { handleSubmit, control } = useForm();
  const [submitting, setIsSubmitting] = useState(false);
  const [city, setCity] = useState(
    cities[1].split("|").map((ele) => ele.trim())
  );
  const [cityVal, setCityVal] = useState(city[0]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { user, setUser, isLoading } = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);
  const navigate = useNavigate();

  const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

  useEffect(() => {
    const fileReaders = [];
    let isCancel = false;
    if (files.length) {
      const promises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReaders.push(fileReader);
          fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
              resolve(result);
            }
          };
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"));
          };
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"));
          };
          fileReader.readAsDataURL(file);
        });
      });
      Promise.all(promises)
        .then((images) => {
          if (!isCancel) {
            setImages(images);
          }
        })
        .catch((reason) => {});
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [files]);

  const onSubmit = (data) => {
    const formdata = new FormData();
    for (const key in data) {
      formdata.append(key, data[key]);
    }
    for (let i = 0; i < files.length; i++) {
      formdata.append("images", files[i]);
    }
    if (files.length === 0) {
      setIsError(true);
      setMessage("Please upload atleast one image");
      return;
    }
    if (!submitting) {
      setIsSubmitting(true);
      setIsError(false);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/post/new`, formdata, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((result) => {
          if (result.status === 201) {
            navigate("/myposts");
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setUser(false);
            } else {
              setMessage(error.response?.data?.message);
              setIsError(true);
            }
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        });
    }
  };

  const handleToastClose = () => {
    setToastErr(false);
  };

  const findCity = (state) => {
    const index = states.indexOf(state) + 1;
    const selectedCity = cities[index].split("|").map((ele) => ele.trim());
    setCity(selectedCity);
    setCityVal(selectedCity[0]);
  };

  const fileChangeHandler = async (e) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    }
    setIsImageUploading(true);
    // await new Promise((r) => setTimeout(r, 2000)); //loading delay
    const { files } = e.target;
    const validImageFiles = [];
    const length = files.length ? (files.length <= 3 ? files.length : 3) : 0; //restrict to 3 images
    for (let i = 0; i < length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        let compressedFile = await imageCompression(file, options);
        validImageFiles.push(compressedFile);
      } else {
        alert("Selected images are not of valid type!");
      }
    }
    if (validImageFiles.length) {
      setFiles(validImageFiles);
    }
    console.log(files);
    setIsImageUploading(false);
  };

  return (
    <>
      {!isLoading && !user && (
        <Box p={3} sx={{ textAlign: "center" }}>
          <Typography variant="h6">
            To upload an adoption post, please{" "}
            <span style={{ fontWeight: 500, color: "rgb(0 149 246)" }}>
              <Link
                to="/login"
                style={{
                  fontWeight: 500,
                  color: "rgb(0 149 246)",
                  textDecoration: "none",
                }}
              >
                Log In
              </Link>
            </span>
          </Typography>
        </Box>
      )}
      <Toolbar />
      {!isLoading && user && (
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Card sx={CardStyle}>
              <Box p={2}>
                <Typography variant="h5" mt={3} mb={3}>
                  Create an adoption post
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        label="Title"
                        onChange={onChange}
                        required
                        variant="outlined"
                        type="text"
                        value={value}
                        error={error ? true : false}
                        fullWidth
                        size="small"
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{
                      required: "Title is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Character limit is 100, please shorten your title",
                      },
                    }}
                  />
                  <br />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        label="Description"
                        onChange={onChange}
                        required
                        multiline
                        rows={4}
                        variant="outlined"
                        type="text"
                        value={value}
                        error={error ? true : false}
                        fullWidth
                        size="small"
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{
                      required: "Description is required",
                      maxLength: {
                        value: 500,
                        message:
                          "Character limit is 1024, please shorten your description",
                      },
                    }}
                  />
                  <br />
                  <Controller
                    name="category"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        id="category"
                        select
                        required
                        onChange={onChange}
                        value={value}
                        label="Category"
                        fullWidth
                        error={error ? true : false}
                        size="small"
                        helperText={error ? error.message : null}
                      >
                        {catergories.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: "Category is required",
                    }}
                  />
                  <br />
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        id="gender"
                        select
                        required
                        onChange={onChange}
                        value={value}
                        label="Gender"
                        fullWidth
                        size="small"
                        error={error ? true : false}
                        helperText={error ? error.message : null}
                      >
                        {genders.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: "Gender is required",
                    }}
                  />
                  <br />
                  <Controller
                    name="age"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyleAge}
                        id="age"
                        inputProps={{ inputMode: "numeric" }}
                        required
                        onChange={onChange}
                        value={value}
                        label="Age"
                        size="small"
                        error={error ? true : false}
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{
                      required: "Age is required",
                      pattern: {
                        value: /^(?:[1-9]|[1-9][0-9])$/,
                        message: "Please enter a valid number",
                      },
                      maxLength: {
                        value: 2,
                        message: "Age cannot be greater than 2 digits",
                      },
                    }}
                  />
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="Years"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="period"
                        select
                        required
                        onChange={onChange}
                        value={value}
                        label=""
                        size="small"
                        error={error ? true : false}
                        helperText={error ? error.message : null}
                      >
                        {ageType.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: "Please choose an option",
                    }}
                  />
                  <br />
                  <Controller
                    name="vaccinated"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        id="vaccinated"
                        select
                        required
                        onChange={onChange}
                        value={value}
                        label="Vaccinated"
                        fullWidth
                        size="small"
                        error={error ? true : false}
                        helperText={error ? error.message : null}
                      >
                        {vaccinated.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: "Please choose an option",
                    }}
                  />
                  <br />
                  <hr
                    style={{
                      height: "0.2px",
                      border: "none",
                      backgroundColor: "#E8E8E8",
                      color: "#E8E8E8",
                    }}
                  />
                  <Typography variant="subtitle1" mt={3} mb={3}>
                    You can upload upto 3 images
                  </Typography>
                  <Button variant="contained" component="label">
                    Select Images
                    <input
                      name="images"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={fileChangeHandler}
                    />
                  </Button>
                  {isImageUploading && (
                    <Box mt={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  {images.length > 0 ? (
                    <ImageList sx={Images} cols={3} rowHeight={40}>
                      {images.map((image, idx) => (
                        <ImageListItem key={idx}>
                          <img src={image} alt="" loading="lazy" />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  ) : null}
                  <hr
                    style={{
                      marginTop: 30,
                      height: "0.2px",
                      border: "none",
                      backgroundColor: "#E8E8E8",
                      color: "#E8E8E8",
                    }}
                  />
                  <Typography variant="h6" mt={3} mb={3}>
                    Location and Contact
                  </Typography>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={states[0]}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        sx={TextFieldStyleState}
                        options={states}
                        size="small"
                        fullWidth
                        required
                        getOptionLabel={(option) => (option ? option : "")}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        value={value}
                        onChange={(_, data) => {
                          findCity(data);
                          onChange(data);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            error={error ? true : false}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    )}
                    rules={{
                      required: "State is required",
                    }}
                  />
                  <br />
                  <Controller
                    name="city"
                    control={control}
                    defaultValue={city[0]}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        sx={TextFieldStyleState}
                        options={city}
                        size="small"
                        fullWidth
                        required
                        getOptionLabel={(option) => (option ? option : "")}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        value={cityVal}
                        onChange={(_, data) => {
                          setCityVal(data);
                          onChange(data);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="City"
                            error={error ? true : false}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    )}
                    rules={{
                      required: "City is required",
                    }}
                  />
                  <br />
                  <Controller
                    name="code"
                    control={control}
                    defaultValue="+91"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyleCode}
                        id="code"
                        type="text"
                        required
                        disabled
                        value="+91"
                        label=""
                        size="small"
                        error={error ? true : false}
                      />
                    )}
                    rules={{
                      required: "Code is required",
                    }}
                  />
                  <Controller
                    name="contact"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="contact"
                        sx={TextFieldStyleContact}
                        inputProps={{ inputMode: "numeric" }}
                        required
                        onChange={onChange}
                        value={value}
                        error={error ? true : false}
                        label="Contact"
                        size="small"
                        helperText={
                          error
                            ? error.message
                            : "This contact number will be displayed on the post"
                        }
                      />
                    )}
                    rules={{
                      required: "Contact Number is required",
                      pattern: {
                        value: /^[0-9]\d{9}$/,
                        message: "Please enter a valid phone number",
                      },
                    }}
                  />
                  <br />
                  {submitting && (
                    <Box mt={1} mb={1}>
                      <CircularProgress
                        sx={{
                          margin: "0 auto",
                          display: "block",
                        }}
                      />
                    </Box>
                  )}
                  {isError && (
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 400, color: "#d32f2f" }}
                      mb={1}
                    >
                      {message}
                    </Typography>
                  )}
                  <Button
                    sx={ButtonStyle}
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Post Now
                  </Button>
                </form>
              </Box>
            </Card>
          </Box>
          {toastErr && (
            <Toast
              open={toastErr}
              handleClose={handleToastClose}
              message="Oops, something went wrong!"
              severity="error"
            />
          )}
        </Container>
      )}
    </>
  );
};

export default Post;
