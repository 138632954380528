export const TextFieldStyle = {
  minWidth: 275,
  maxWidth: 350,
  mb: 2,
};

export const CardStyle = {
  minWidth: 350,
  display: "inline-block",
  backgroundColor: "white",
  boxShadow: 2,
};

export const ButtonStyle = {
  minWidth: 275,
  maxWidth: 350,
  width: "100%",
  height: 39,
  textTransform: "none",
  backgroundColor: "rgb(0 149 246)",
  color: "#fff",
  "&.MuiButtonBase-root:hover": {
    backgroundColor: "rgb(0 160 265)",
  },
};
