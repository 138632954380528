import React from "react";
import { Box,Toolbar,Typography } from "@mui/material";

const Footer = ()=> {

    return (
        <Box mt={2} sx={{ backgroundColor: "#F5F5F5", bottom: 0, left: 0, right: 0, position: "relative" }}>
            <Toolbar sx={{justifyContent: "center"}}>
                <Typography color="black" variant="caption" >© 2023 Adoptypet </Typography>
            </Toolbar>
        </Box>
    )
}

export default Footer