import Login from "./auth/Login";
import SignUp from "./auth/Signup";
import Home from "./home/Home";
import Post from "./posts/Post";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import ViewPost from "./posts/ViewPost";
import { UserContext } from "./hooks/UserContext";
import useLoggedIn from "./hooks/useLoggedIn";
import Logout from "./auth/Logout";
import PrivateRoute from "./auth/PrivateRoute";
import ResetPassword from "./auth/ResetPassword";
import ForgotPassword from "./auth/ForgotPassword";
import Contact from "./contact/Contact";

const App = () => {
  const { user, setUser, isLoading } = useLoggedIn();
  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <Navbar />
        <Routes>
          <Route excat path="/" element={<Home />} />
          <Route excat path="/login" element={<Login />} />
          <Route excat path="/logout" element={<Logout />} />
          <Route excat path="/signup" element={<SignUp />} />
          <Route excat path="/reset_password/:token" element={<ResetPassword />} />
          <Route excat path="/forgot_password" element={<ForgotPassword />} />
          <Route excat path="/post/:id/" element={<ViewPost />} />
          <Route excat path="/post" element={<Post />} />
          <Route excat path="/contact" element={<Contact />} />
          <Route
            excat
            path="/myposts"
            element={<PrivateRoute route="myposts" />}
          />
          <Route
            excat
            path="/myposts/:id/"
            element={<PrivateRoute route="userpost" />}
          />
        </Routes>
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default App;
