import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Carousel from "./Images";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const ViewPostUser = () => {
  const [data, setData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const { setUser } = useContext(UserContext);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    const postId = id.substring(id.lastIndexOf("-") + 1, id.length);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/post/` + postId, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((result) => {
        if (result.status === 200) {
          setData(result.data.post);
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setUser(false);
            navigate("/login");
          } else {
            setMessage(error.response?.data?.message);
            setToastErr(true);
          }
        } else {
          setMessage("Oops, something went wrong!");
          setToastErr(true);
        }
        setIsSubmitting(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleToastClose = () => {
    setToastErr(false);
  };

  return (
    <>
      {data && Object.keys(data).length !== 0 && (
        <Carousel data={data} fromMyPosts={true} />
      )}
      {toastErr && (
        <Toast
          open={toastErr}
          handleClose={handleToastClose}
          message={message}
          severity="error"
        />
      )}
    </>
  );
};

export default ViewPostUser;
