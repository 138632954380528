import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";

import SinglePost from "../carditem/SinglePost";
import { Link } from "react-router-dom";
import Toast from "../shared/Snackbar";

const Home = () => {
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [pageConfig, setPageConfig] = useState({ page: 1, offset: 10 });
  const [postRefId, setPostRefId] = useState(0);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);

  useEffect(() => {
    setIsSubmitting(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/feed/posts?page=` +
          pageConfig.page +
          "&offset=" +
          pageConfig.offset +
          "&afterPostNum=" +
          postRefId,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          //set post ref id from first page for subsequent api calls
          if (pageConfig.page === 1) {
            setPostRefId(result?.data?.posts[0]?.id);
          }
          // reached end of pagination
          if (result.data?.posts?.length < 10) {
            setIsReachedEnd(true);
          }
          //set data to show
          setData((prev) => {
            return [...prev, ...result.data.posts];
          });
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error.response) {
          setMessage(error.response?.data?.message);
          setToastErr(true);
        } else {
          setMessage("Oops, something went wrong!");
          setToastErr(true);
        }
        setIsSubmitting(false);
      });
    // eslint-disable-next-line
  }, [pageConfig]);

  const handleToastClose = () => {
    setToastErr(false);
  };

  const getMoreItems = () => {
    setPageConfig((prev) => {
      return {
        ...prev,
        page: prev.page + 1,
      };
    });
  };

  return (
    <>
      <Container>
        {!isSubmitting && !toastErr && data.length === 0 && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>Nothing to see here.</Typography>
          </Box>
        )}
        {data.length > 0 &&
          data.map((post) => {
            return (
              <Link
                key={post.id}
                to={
                  "/post/" +
                  post.title.substring(0, 20).replace(/ /g, "-") +
                  "-" +
                  post.id
                }
                style={{ textDecoration: "none" }}
              >
                <SinglePost post={post} />
              </Link>
            );
          })}
        {isSubmitting && (
          <Box mt={2}>
            <CircularProgress
              sx={{
                margin: "0 auto",
                display: "block",
              }}
            />
          </Box>
        )}
        {!isSubmitting && !isReachedEnd && data.length > 0 && (
          <Box mt={2} mb={2}>
            <Button
              sx={{
                margin: "0 auto",
                display: "block",
                color: "rgb(0 149 246)",
              }}
              variant="outlined"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={getMoreItems}
            >
              Load More
            </Button>
          </Box>
        )}
        {toastErr && (
          <Toast
            open={toastErr}
            handleClose={handleToastClose}
            message={message}
            severity="error"
          />
        )}
      </Container>
    </>
  );
};

export default Home;
