import MyPosts from "../myposts/MyPosts";
import { useContext, useEffect } from "react";
import { UserContext } from "../hooks/UserContext";
import ViewPostUser from "../posts/ViewPostUser";
import { useNavigate } from "react-router-dom";

const PrivateRoute = (props) => {

    const route = props.route;
    const { user, isLoading } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !user) {
          navigate("/login");
        }
      // eslint-disable-next-line
      }, [isLoading]);

    const getPrivateRoute = ()=> {
        if(route){
            if(route === 'myposts'){
                return <MyPosts />
            }
            else if(route === 'userpost'){
                return <ViewPostUser />
            }
        }
    }

    return !isLoading && user && <>{getPrivateRoute()}</>;
}

export default PrivateRoute;