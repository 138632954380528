import {
  TextField,
  Button,
  Container,
  Box,
  Toolbar,
  Typography,
  Card,
} from "@mui/material";
import { TextFieldStyle, CardStyle, ButtonStyle } from "./Style";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const Login = () => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const [submitting, setIsSubmitting] = useState(false);
  const { user, setUser, isLoading } = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);

  const onSubmit = (data) => {
    if (!submitting) {
      setIsSubmitting(true);
      setIsError(false);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((result) => {
          if (result.status === 200) {
            setUser(true);
            navigate("/");
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response?.data?.message);
            setIsError(true);
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        });
    }
  };

  const handleToastClose = () => {
    setToastErr(false);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Toolbar />
      <Container>
        {!isLoading && !user && (
          <Box sx={{ textAlign: "center" }}>
            <Card sx={CardStyle}>
              <Box p={2}>
                <Typography variant="h5" mt={3} mb={!isError ? 3 : 2}>
                  Log in
                </Typography>
                {isError && (
                  <Typography
                    sx={{ fontSize: 13, fontWeight: 400, color: "#d32f2f" }}
                    mb={1}
                  >
                    {message}
                  </Typography>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        label="Email"
                        onChange={onChange}
                        required
                        variant="outlined"
                        type="email"
                        value={value}
                        error={error ? true : false}
                        fullWidth
                        size="small"
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{ required: "Email required" }}
                  />
                  <br />
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={TextFieldStyle}
                        label="Password"
                        onChange={onChange}
                        required
                        variant="outlined"
                        type="password"
                        value={value}
                        error={error ? true : false}
                        fullWidth
                        size="small"
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{
                      required: "Password is required",
                      // minLength: {
                      //   value: 6,
                      //   message: "Password should be between 6 - 16 characters",
                      // },
                    }}
                  />
                  <br />
                  <Typography mb={2} sx={{ fontSize: "14px" }}>
                    <span style={{ fontWeight: 500 }}>
                      <Link
                        to="/forgot_password"
                        style={{
                          fontWeight: 500,
                          color: "rgb(0 149 246)",
                          textDecoration: "none",
                        }}
                      >
                        forgot password?
                      </Link>
                    </span>
                  </Typography>
                  <Button
                    sx={ButtonStyle}
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Log in
                  </Button>
                  <Typography mt={2} mb={3} sx={{ fontSize: 14 }}>
                    Don't have an account?{" "}
                    <span style={{ fontWeight: 500, color: "rgb(0 149 246)" }}>
                      <Link
                        to="/signup"
                        style={{
                          fontWeight: 500,
                          color: "rgb(0 149 246)",
                          textDecoration: "none",
                        }}
                      >
                        Sign Up
                      </Link>
                    </span>
                  </Typography>
                </form>
              </Box>
            </Card>
          </Box>
        )}
        {toastErr && (
          <Toast
            open={toastErr}
            handleClose={handleToastClose}
            message="Oops, something went wrong!"
            severity="error"
          />
        )}
      </Container>
    </>
  );
};

export default Login;
