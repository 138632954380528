import {
  TextField,
  Button,
  Container,
  Box,
  Toolbar,
  Typography,
  Card,
} from "@mui/material";
import { TextFieldStyle, CardStyle, ButtonStyle } from "./Style";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const ResetPassword = () => {
  const { handleSubmit, control } = useForm();
  const [submitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);
  const token = useParams();

  const onSubmit = (data) => {
    setIsError(false);
    if (data.newPassword !== data.confirmPassword) {
      setMessage("New Password and Confirm Password do not match");
      setIsError(true);
      return;
    }

    if (!submitting) {
      setIsSubmitting(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/auth/reset_password`,
          { password: data.newPassword },
          {
            headers: { "Content-Type": "application/json", token: token.token },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setSuccess(true);
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response?.data?.message);
            setIsError(true);
          } else {
            setToastErr(true);
          }
          setIsSubmitting(false);
        });
    }
  };

  const handleToastClose = () => {
    setToastErr(false);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Toolbar />
      <Container>
        {!isLoading && !user && (
          <Box sx={{ textAlign: "center" }}>
            {success ? (
              <Box p={3} sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  Password has been reset, please{" "}
                  <span style={{ fontWeight: 500, color: "rgb(0 149 246)" }}>
                    <Link
                      to="/login"
                      style={{
                        fontWeight: 500,
                        color: "rgb(0 149 246)",
                        textDecoration: "none",
                      }}
                    >
                      Log In
                    </Link>
                  </span>{" "}
                  to continue.
                </Typography>
              </Box>
            ) : (
              <Card sx={CardStyle}>
                <Box p={2}>
                  <Typography variant="h5" mt={3} mb={!isError ? 3 : 2}>
                    Reset Password
                  </Typography>
                  {isError && (
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 400, color: "#d32f2f" }}
                      mb={1}
                    >
                      {message}
                    </Typography>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="newPassword"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          sx={TextFieldStyle}
                          label="New Password"
                          onChange={onChange}
                          required
                          variant="outlined"
                          type="password"
                          value={value}
                          error={error ? true : false}
                          fullWidth
                          size="small"
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message:
                            "Password should be between 6 - 16 characters",
                        },
                      }}
                    />
                    <br />
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          sx={TextFieldStyle}
                          label="Confirm Password"
                          onChange={onChange}
                          required
                          variant="outlined"
                          type="password"
                          value={value}
                          error={error ? true : false}
                          fullWidth
                          size="small"
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message:
                            "Password should be between 6 - 16 characters",
                        },
                      }}
                    />
                    <br />
                    <Box mb={3}>
                      <Button
                        sx={ButtonStyle}
                        variant="outlined"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Card>
            )}
          </Box>
        )}
        {toastErr && (
          <Toast
            open={toastErr}
            handleClose={handleToastClose}
            message="Oops, something went wrong!"
            severity="error"
          />
        )}
      </Container>
    </>
  );
};

export default ResetPassword;
