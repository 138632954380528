export const TextFieldStyle = {
  minWidth: { xs: 275, sm: 350, md: 450 },
  maxWidth: { xs: 350, sm: 450, md: 550 },
  mb: 3.5,
  mr: { sm: 4 },
};

export const TextFieldStyleState = {
  minWidth: { xs: 275, sm: 350, md: 450 },
  maxWidth: { xs: 350, sm: 450, md: 550 },
  mb: 1,
  mr: { sm: 4 },
};

export const TextFieldStyleAge = {
  minWidth: { xs: 200, sm: 300, md: 400 },
  maxWidth: { xs: 275, sm: 400, md: 500 },
  mb: 3.5,
  mr: { xs: 1 },
};

export const TextFieldStyleContact = {
  minWidth: { xs: 185, sm: 300, md: 420 },
  maxWidth: { xs: 255, sm: 410, md: 510 },
  mb: 3.5,
  ml: 1,
};

export const TextFieldStyleCode = {
  minWidth: { xs: 55, sm: 65, md: 65 },
  maxWidth: { xs: 55, sm: 70, md: 70 },
  mb: 3.5,
  display: "inline-flex",
};

export const CardStyle = {
  minWidth: 350,
  display: "inline-block",
  backgroundColor: "white",
  boxShadow: 2,
};

export const Images = {
  width: { xs: 300, sm: 400, md: 500 },
  height: { xs: 100, sm: 100, md: 200 },
};

export const ButtonStyle = {
  minWidth: 275,
  maxWidth: 350,
  width: "100%",
  height: 39,
  textTransform: "none",
  backgroundColor: "rgb(0 149 246)",
  color: "#fff",
  "&.MuiButtonBase-root:hover": {
    backgroundColor: "rgb(0 160 265)",
  },
};
