import React from 'react';
import './Layout.css';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <div className="Content">{children}</div>
      <footer className="Footer">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
