import { useEffect, useState } from "react";
import axios from "axios";
import { Container, Typography } from "@mui/material";

import SinglePost from "../carditem/SinglePost";
import { Link } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const MyPosts = () => {
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [pageConfig, setPageConfig] = useState({ page: 1, offset: 10 });
  const [postRefId, setPostRefId] = useState(0);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const { setUser } = useContext(UserContext);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsSubmitting(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user/posts?page=` +
          pageConfig.page +
          "&offset=" +
          pageConfig.offset +
          "&afterPostNum=" +
          postRefId,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.status === 200) {
          //set post ref id from first page for subsequent api calls
          if (pageConfig.page === 1) {
            setPostRefId(result?.data?.posts[0]?.id);
          }
          // reached end of pagination
          if (result.data?.posts?.length < 10) {
            setIsReachedEnd(true);
          }
          //set data to show
          setData((prev) => {
            return [...prev, ...result.data.posts];
          });
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setUser(false);
            navigate("/login");
          } else {
            setMessage(error.response?.data?.message);
            setToastErr(true);
          }
        } else {
          setMessage("Oops, something went wrong!");
          setToastErr(true);
        }
        setIsSubmitting(false);
      });
    // eslint-disable-next-line
  }, [pageConfig]);

  const handleToastClose = () => {
    setToastErr(false);
  };

  const getMoreItems = () => {
    setPageConfig((prev) => {
      return {
        ...prev,
        page: prev.page + 1,
      };
    });
  };

  return (
    <div>
      {!isSubmitting && !toastErr && data.length === 0 && (
        <Box sx={{ textAlign: "center" }}>
          <Typography>You have not uploaded any adoption posts yet</Typography>
        </Box>
      )}
      {data.length > 0 && (
        <Container>
          <Box mb={2} sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: 600 }} variant="h5">
              My posts
            </Typography>
          </Box>
          {data.map((post) => {
            return (
              <Link
                to={
                  "/myposts/" +
                  post.title.substring(0, 20).replace(/ /g, "-") +
                  "-" +
                  post.id
                }
                style={{ textDecoration: "none" }}
              >
                <SinglePost post={post} />
              </Link>
            );
          })}
          {isSubmitting && (
            <Box mt={2}>
              <CircularProgress
                sx={{
                  margin: "0 auto",
                  display: "block",
                }}
              />
            </Box>
          )}
          {!isSubmitting && !isReachedEnd && data.length > 0 && (
            <Box mt={2} mb={2}>
              <Button
                sx={{
                  margin: "0 auto",
                  display: "block",
                  color: "rgb(0 149 246)",
                }}
                variant="outlined"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                onClick={getMoreItems}
              >
                Load More
              </Button>
            </Box>
          )}
        </Container>
      )}
      {toastErr && (
        <Toast
          open={toastErr}
          handleClose={handleToastClose}
          message={message}
          severity="error"
        />
      )}
    </div>
  );
};

export default MyPosts;
