import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Container } from "@mui/material";

const Contact = () => {
  const header = "Email";
  const data = "Send your queries to;support@adoptypet.com";

  const getIcon = (icon) => {
    return <EmailIcon mt={2} sx={{ fontSize: 40 }} />;
  };

  return (
    <Container>
      <Box sx={{ textAlign: "center" }}>
        <Box mb={3}>
          <Typography variant="h5" sx={{ fontWeight: 600, lineHeight: 1.5 }}>
            Contact Us
          </Typography>
        </Box>
        <Card
          p={3}
          sx={{
            minWidth: 350,
            backgroundColor: "rgb(249 250 251)",
            boxShadow: "none",
            display: "inline-block",
          }}
        >
          <CardContent>
            {getIcon()}
            <Typography variant="h6" component="div">
              {header}
            </Typography>
            {data.split(";").map((text, key) => {
              return (
                <Typography key={key} mt={2} variant="body1">
                  {text}
                </Typography>
              );
            })}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Contact;
