import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Icon,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./Carousel.css";
import { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Toast from "../shared/Snackbar";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <ArrowBackIos {...props}></ArrowBackIos>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <ArrowForwardIos {...props}></ArrowForwardIos>
);

const Carousel = (props) => {
  const data = props.data;
  const fromMyPosts = props.fromMyPosts;
  const [open, setOpen] = useState(false);
  const { setUser } = useContext(UserContext);
  const [message, setMessage] = useState("Oops, something went wrong");
  const [toastErr, setToastErr] = useState(false);
  const [toastSucc, setToastSucc] = useState(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const settings = {
    dots: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    infinite: true,
    prevArrow: <SlickArrowLeft sx={{ color: "black" }} />,
    nextArrow: <SlickArrowRight sx={{ color: "black" }} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const share = async () => {
    if (navigator.canShare()) {
      await navigator.share({
        url: "https://adoptypet.com/post/"+ window.location.href.split("/").pop(),
      });
    } else {
      await navigator.clipboard.writeText("https://adoptypet.com/post/"+ window.location.href.split("/").pop());
      setMessage("Link copied to clipboard");
      setToastSucc(true);
    }
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    return setOpen(false);
  };

  const handleAdoptedSumbit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      axios.defaults.withCredentials = true;
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/post/adopted`,
          { postId: data.id },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            data.isAdopted = true;
            handleClose();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setUser(false);
              navigate("/login");
            } else {
              setMessage(error.response?.data?.message);
              setToastErr(true);
            }
          } else {
            setMessage("Oops, something went wrong!");
            setToastErr(true);
          }
          setIsSubmitting(false);
        });
    }
  };

  const handleToastClose = () => {
    setToastErr(false);
  };

  const handleToastSuccClose = () => {
    setToastSucc(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <Paper
            variant="outlined"
            className="carousel-container"
            sx={{
              width: { xs: "90%", sm: "95%", md: "60%" },
              boxShadow: "none",
            }}
          >
            <Slider {...settings}>
              {data?.images?.map((imageUrl, index) => (
                <div key={index}>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/` + imageUrl}
                    alt={`Slide ${index + 1}`}
                    className="carousel-image"
                  />
                </div>
              ))}
            </Slider>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ height: { md: "100vh" } }} overflow="auto">
            <Paper
              variant="outlined"
              sx={{
                width: { xs: "90%", sm: "95%", md: "75%" },
                pl: 2,
                pt: 1,
                boxShadow: "none",
                margin: { xs: "auto", md: "0" },
                mt: { xs: 4, md: 0 },
                mb: { xs: 1, sm: 1, md: 1, lg: 1 },
              }}
            >
              {!data.isAdopted ? (
                <>
                  <IconButton
                    disabled
                    disableRipple={true}
                    sx={{
                      color: "black",
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    size="small"
                  >
                    <CallIcon />
                  </IconButton>
                  <Typography
                    ml={0.5}
                    mr={2}
                    variant="subtitle1"
                    display="inline"
                  >
                    {data.code + " " + data.contact}
                  </Typography>
                  <IconButton
                    sx={{ color: "black" }}
                    size="small"
                    onClick={share}
                  >
                    <ShareIcon />
                    <Typography ml={0.5} variant="subtitle1" display="inline">
                      Share
                    </Typography>
                  </IconButton>
                  {fromMyPosts && (
                    <IconButton
                      sx={{ color: "black" }}
                      size="small"
                      onClick={handleModalOpen}
                    >
                      <FavoriteBorderIcon />
                      <Typography ml={0.5} variant="subtitle1" display="inline">
                        Mark as Adopted
                      </Typography>
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  sx={{
                    color: "black",
                    "&.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  size="small"
                  disabled={data.isAdopted}
                >
                  <FavoriteIcon />
                  <Typography ml={0.5} variant="subtitle1" display="inline">
                    Marked as Adopted!{" "}
                    {fromMyPosts
                      ? ""
                      : "Please look at other adoption requests."}
                  </Typography>
                </IconButton>
              )}

              <Typography mb={0.5}></Typography>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                width: { xs: "90%", sm: "95%", md: "75%" },
                pl: 2,
                pt: 1,
                boxShadow: "none",
                position: "relative",
                margin: { xs: "auto", md: "0" },
                mb: { xs: 1, sm: 1, md: 1, lg: 1 },
              }}
            >
              <Typography variant="h5">{data.title}</Typography>
              <Typography mt={2} variant="subtitle1">
                Category: {data.category}
              </Typography>
              <Typography variant="subtitle1">Gender: {data.gender}</Typography>
              <Typography variant="subtitle1">
                Age: {data.age + " " + data.period}
              </Typography>
              <Typography variant="subtitle1">
                Vaccinated: {data.vaccinated}
              </Typography>
              <Typography mt={2} variant="h6">
                Posted At
              </Typography>
              <Icon mb={1} size="small">
                <LocationOnIcon />
              </Icon>
              <Typography variant="subtitle1" ml={1} display="inline">
                {data.city + ", " + data.state}
              </Typography>
              <Typography mb={1}></Typography>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                width: { xs: "90%", sm: "95%", md: "75%" },
                pl: 2,
                pt: 1,
                mt: 1,
                boxShadow: "none",
                margin: { xs: "auto", md: "0" },
                mb: { xs: 1, sm: 1 },
              }}
            >
              <Typography variant="h6">More Details</Typography>

              <Typography
                sx={{ whiteSpace: "pre-line" }}
                mb={1}
                variant="subtitle1"
              >
                {data.description}
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="adopted-dialog-title"
        aria-describedby="adopted-dialog-description"
      >
        <DialogTitle id="adopted-dialog-title">
          {"Mark Post as Adopted?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="adopted-dialog-description">
            Are you sure you want to mark this post as Adopted? Once done, the
            listing will be removed and will be visisble only to you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdoptedSumbit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {toastErr && (
        <Toast
          open={toastErr}
          handleClose={handleToastClose}
          message={message}
          severity="error"
        />
      )}
      {toastSucc && (
        <Toast
          open={toastSucc}
          handleClose={handleToastSuccClose}
          message={message}
          severity="success"
        />
      )}
    </>
  );
};

export default Carousel;
